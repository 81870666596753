import request from "@/utils/request";

const api = {
  getAlarmEvent: "/dsmcore/api/worker/eventlist", // 查询报警事件
  getSiteNeat: "/dsmcore/api/worker/imagelist", // 查询工地整洁
  getPickup: "/dsmcore/api/snapshot/pickup", //纠正照片
  getPickuplog: "/dsmcore/api/snapshot/pickuplog", //纠正日志
};

export function GetAlarmEvent(parameter) {
  return request({
    url: api.getAlarmEvent,
    method: "post",
    params: parameter,
  });
  // return new Promise((resolve) => {
  //   const data = {
  //     message: "",
  //     data: {
  //       data: [
  //         {
  //           id: "1",
  //           bc_name: "北京分公司",
  //           ws_name: "工地名称111",
  //           w_name_jl: "项目经理",
  //           w_name_gj: "工地管家",
  //           e_events: "nohelmet",
  //           e_img: "",
  //           e_time: "1640092039",
  //         },
  //       ],
  //       page: 0,
  //       sum: 1,
  //     },
  //     status: 200,
  //   };
  //   resolve(data);
  // });
}

export function GetSiteNeat(parameter) {
  return request({
    url: api.getSiteNeat,
    method: "post",
    params: parameter,
  });
  // return new Promise((resolve) => {
  //   const data = {
  //     message: "",
  //     data: {
  //       data: [
  //         {
  //           id: "1",
  //           bc_name: "北京分公司",
  //           ws_name: "工地名称111",
  //           w_name_jl: "项目经理",
  //           w_name_gj: "工地管家",
  //           e_events: "1",
  //           e_img: "",
  //           e_time: "1640092039",
  //         },
  //       ],
  //       page: 0,
  //       sum: 1,
  //     },
  //     status: 200,
  //   };
  //   resolve(data);
  // });
}
export function GetPickup(parameter) {
  return request({
    url: api.getPickup,
    method: "post",
    params: parameter,
  });
}
export function GetPickuplog(parameter) {
  return request({
    url: api.getPickuplog,
    method: "post",
    params: parameter,
  });
}
